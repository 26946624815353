.pp-body {
  font-family: "Roboto";
  background: #edf4f8;
}

.privacy-block {
  padding: 20px;
  margin: 10px auto;
  max-width: 1140px;
  background: #fff;
}

.privacy-block .title {
  font-size: 30px;
  color: #0072b8;
  text-transform: uppercase;
  padding-bottom: 20px;
}

.privacy-block .privacy-text h3 {
  color: #181c32;
  font-size: 18px;
  line-height: 20px;
  font-weight: 500;
  margin: 0;
  padding: 0 0 10px;
}

.privacy-block .privacy-text p {
  font-size: 14px;
  line-height: 16px;
  color: #7e8299;
  margin: 0;
  padding: 5px 0 30px;
}
