//
// Aside light theme
//


// Initialization of global variables, mixins and functions
@import "../../../init";

// Base
.aside {
	background-color: get($layout-themes, light);
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.04);
	border-top: 1px solid #e0e2f0;
	// Aside Menu
	.aside-menu {
		// Scrollbar
		@include perfect-scrollbar-theme(#e0e2f0);
	}
}

// Build aside menu theme
@include menu-ver-build-theme($aside-menu-config, light);
