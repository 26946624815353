// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/light.scss";

// Brand themes
.brand-dark {
  @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
  @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

.example-highlight>pre {
  background: none transparent !important;
  margin: 0 !important;
  padding-top: 0.5rem;

  code {
    overflow-y: auto;
    display: block;

    span {
      background: none transparent !important;
    }
  }
}

.json>pre {
  background: none #fff !important;

}

.example-code>.json {
  background: none #fff !important;
}

.symbol.symbol-45>svg {
  width: 100%;
  max-width: 45px;
  height: 45px;
}

.react-bootstrap-table {
  overflow-x: auto;
}

.react-bootstrap-table {
  th {
    outline: none;

    &.sortable {
      .svg-icon-sort {
        opacity: 0;
      }

      &:hover {
        cursor: pointer;

        .svg-icon-sort {
          opacity: 1;
        }
      }
    }
  }

  .table.table-head-custom thead tr {
    .sortable-active {
      color: $primary !important;
    }
  }
}

.cursor-default {
  cursor: default !important;
}

.display-block {
  display: block;
}

.pwd-group {
  position: relative;
}

.pwd-group .pwd-copy {
  position: absolute;
  right: 20px;
  top: 40px;
  cursor: pointer;
}

.pwd-generate {
  cursor: pointer;
}

.modal-open {
  overflow: hidden !important;

  .modal {
    z-index: 9999;
  }
}

.IntechChatBot {
  .rsc-container {
    width: 100%;
    max-width: 98%;
    height: auto;
    left: 0;
    right: 0;
    top: auto;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 600px) {
  .IntechChatBot {
    .rsc-container {
      max-width: 100%;
    }
  }
}