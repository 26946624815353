.full-layout #kt_wrapper {
    padding-left: 0;
}

.loader_wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: #48424238;
    z-index: 1;
    left: 0;
    right: 0;
}

.nodata__block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    background: #fff;
}

.btn.btn-icon.iconBtn {
    width: 35px;
    height: 35px;
    border-radius: 4px;
    margin-right: 10px;
    padding: 6px;
}

.btn-hover-primary.iconBtn:hover svg path,
.btn-hover-primary.iconBtn:focus svg path {
    fill: #fff;
    stroke: #fff;
}

.download__files__block a.btn {
    margin: 8px 8px 8px 0;
    max-width: 220px;
    font-size: 12px;
    cursor: pointer;
}

.back {
    width: 15px !important;
    padding-left: 3px !important;
}

/* .download {
    color: #3699FF
}
 
.download:focus {
    color: white !important;
} */


.toolbox__uploadfile {
    position: relative;
    display: flex;
    align-items: center;
}

.toolbox__uploadfile .uploadicon,
.toolbox__uploadfile .uploadimg {
    height: 54px;
    width: 54px;
    background: #EBEBEB;
    margin-right: 12px;
    padding: 10px;
    flex: 0 0 54px;
    border-radius: 5px;
}

.toolbox__uploadfile .uploadicon {
    background: #EBEBEB url(/media/svg/upload_icon.svg) no-repeat center center;
    background-size: 36px;
}

.toolbox__uploadfile .uploadimg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.toolbox__uploadfile .uploadicon {
    background: #EBEBEB url(/media/svg/upload_icon.svg) no-repeat center center;
    background-size: 36px;
}

.toolbox__uploadfile .uploadimg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.toolbox__uploadfile .form-control {
    flex: 1 1 auto;
}

.toolbox__uploadfile .file__browse {
    height: 40px;
    width: 102px;
    background: #E6E6E6;
    flex: 0 0 102px;
    border-radius: 5px;
    position: absolute;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 20px;
    color: #7E8299;
    padding: 9px 18px;
    text-align: center;
    cursor: pointer;
    right: 10px;
    top: 7px;
}


.ToolboxDetails__img {
    background: #EBEBEB;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 150px;
    overflow: hidden;
    padding: 0 !important;
}

.ToolboxDetails__img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.select-control {
    appearance: none;
    background: url(/media/svg/arrow_down.svg) no-repeat center right 20px;
    background-size: 14px;
}

.aside-left {
    margin-right: 0;
    overflow: hidden;
}

.aside-fixed .aside-menu {
    height: auto !important;
    overflow: hidden;
    overflow-y: auto !important;
    max-height: calc(100vh - 65px);
    margin-right: -15px;
}

.aside-fixed .aside-menu .menu-nav {
    padding: 0;
}

.aside-fixed .aside .menu-item.menu-item-open .menu-link,
.aside-fixed .aside .menu-item .menu-link.active {
    background: transparent !important;
}

.aside-fixed .aside .menu-item {
    position: relative;
}

.aside-fixed .aside-menu .menu-nav .menu-item.menu-item-active .menu-link span {
    font-weight: 600;
}

.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-heading .menu-text,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-active>.menu-link .menu-text {
    font-weight: 600;
}

.aside-fixed .aside-menu .menu-nav .menu-item.menu-item-active .menu-link.active {
    background-color: rgba(77, 89, 149, 0.15) !important;
}

.aside-fixed .aside-menu .menu-nav .menu-item.menu-item-active .menu-link.active span {
    font-weight: 600;
}

.aside-fixed .aside-menu .menu-nav>.menu-item>.menu-link {
    padding: 12px 25px;
}

.aside-fixed .aside-menu .menu-nav>.menu-item .menu-submenu .menu-item {
    line-height: 40px;
}

.aside-fixed .aside-menu .menu-item-submenu .menu-submenu .menu-item .menu-toggle {
    line-height: normal;
}

.aside-fixed .aside .menu-item .sidemenu__plusicon {
    background: url(/media/svg/plusicon.svg) no-repeat center center;
    background-size: 16px;
    display: inline-block;
    min-height: 20px;
    min-width: 20px;
    margin: 0 0 0 10px;
    cursor: pointer;
    position: absolute;
    right: 20px;
    top: 12px;
}

.aside-fixed .aside .menu-item .sidemenu__plusicon.orangeIcon {
    background: url(/media/svg/plusicon-orange.svg) no-repeat center center;
    background-size: 16px;
}

.aside-fixed .aside .menu-item .sidemenu__plusicon.blueIcon {
    background: url(/media/svg/plusicon-blue.svg) no-repeat center center;
    background-size: 16px;
}

.form-group.multiselect-dropdown {
    position: relative;
}

.suggestion__block {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.10);
    margin: 0;
    padding: 8px 0;
    list-style: none;
    border-radius: 6px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
}

.suggestion__block li {
    padding: 8px 20px;
    text-transform: capitalize;
    cursor: pointer;
}

.form-group.multiselect-dropdown {
    position: relative;
}

.suggestion__block {
    background: #fff;
    border: 1px solid rgba(0, 0, 0, 0.10);
    margin: 0;
    padding: 8px 0;
    list-style: none;
    border-radius: 6px;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
}

.suggestion__block li {
    padding: 8px 20px;
    text-transform: capitalize;
    cursor: pointer;
}

.arrowright {
    background: url(/media/svg/next_arrow.svg) no-repeat;
    background-size: 6px;
    display: inline-block;
    height: 10px;
    width: 10px;
    cursor: pointer;
}

.lock-icon svg {
    fill: #0072B8;
}

.lock-icon:hover svg {
    fill: #ffffff;
}

.nodata__icon.svg-icon svg {
    width: auto;
    height: auto;
}

.general__info__block,
.revenue_info__block,
.unitper_order__block {
    padding: 20px 20px 0;
    border-radius: 5px;
    background: #fbfbfb;
    margin-bottom: 20px;
}

.report-div-html {
    max-height: 400px;
    overflow-y: scroll;
    height: 100%;
    background-color: #fff;

    .networkComputationReportTbl {
        tr {
            mso-height-source: auto;
        }

        col {
            mso-width-source: auto;
        }

        br {
            mso-data-placement: same-cell;
        }

        .bt-rm {
            border-top: none !important;
        }

        .bl-rm {
            border-left: none !important;
        }

        .br-rm {
            border-right: none !important;
        }

        .bb-rm {
            border-bottom: none !important;
        }

        .green-bg {
            background: #B4DE86;
        }

        .border-all {
            border: 1.5pt solid windowtext !important;
        }

        .border-rm {
            border: none !important;
        }

        .b-bottom {
            border-bottom: 1.5pt solid windowtext !important;
        }

        .b-top {
            border-top: 1.5pt solid windowtext !important;
        }

        .b-left {
            border-left: 1.5pt solid windowtext !important;
        }

        .b-right {
            border-right: 1.5pt solid windowtext !important;
        }

        .style0 {
            mso-number-format: General;
            text-align: general;
            vertical-align: bottom;
            white-space: nowrap;
            mso-rotate: 0;
            mso-background-source: auto;
            mso-pattern: auto;
            color: windowtext;
            font-size: 12.0pt;
            font-weight: 400;
            font-style: normal;
            text-decoration: none;
            font-family: Calibri;
            mso-generic-font-family: auto;
            mso-font-charset: 0;
            border: none;
            mso-protection: locked visible;
            mso-style-name: Normal;
            mso-style-id: 0;
        }

        td {
            mso-style-parent: style0;
            padding-top: 1px;
            padding-right: 1px;
            padding-left: 1px;
            mso-ignore: padding;
            color: windowtext;
            font-size: 12.0pt;
            font-weight: 400;
            font-style: normal;
            text-decoration: none;
            font-family: Calibri;
            mso-generic-font-family: auto;
            mso-font-charset: 0;
            mso-number-format: General;
            text-align: general;
            vertical-align: bottom;
            border: none;
            mso-background-source: auto;
            mso-pattern: auto;
            mso-protection: locked visible;
            white-space: nowrap;
            mso-rotate: 0;
        }

        .xl65 {
            mso-style-parent: style0;
            font-size: 26.0pt;
            font-weight: 700;
            text-align: left;
            vertical-align: middle;
            border: .5pt dashed windowtext;
        }

        .xl66 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border: .5pt dashed windowtext;
        }

        .xl67 {
            mso-style-parent: style0;
            mso-number-format: "\\$0\.00";
            text-align: center;
            vertical-align: middle;
            border: .5pt dashed windowtext;
        }

        .xl68 {
            mso-style-parent: style0;
            font-size: 14.0pt;
            font-weight: 700;
            text-align: center;
            vertical-align: middle;
            border: .5pt dashed windowtext;
        }

        .xl69 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: 1.5pt solid windowtext;
        }

        .xl70 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl71 {
            mso-style-parent: style0;
            mso-number-format: "\\$0\.00";
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl72 {
            mso-style-parent: style0;
            mso-number-format: "\\$0\.00";
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: 1.5pt solid windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl73 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: .5pt dashed windowtext;
            background: #FFFF33;
            mso-pattern: black none;
        }

        .xl74 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: 1.5pt solid windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl75 {
            mso-style-parent: style0;
            font-weight: 700;
            text-align: center;
            vertical-align: middle;
            border: .5pt dashed windowtext;
        }

        .xl76 {
            mso-style-parent: style0;
            font-weight: 700;
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: 1.5pt solid windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: 1.5pt solid windowtext;
        }

        .xl77 {
            mso-style-parent: style0;
            mso-number-format: "\\$0\.00";
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: 1.5pt solid windowtext;
        }

        .xl78 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: 1.5pt solid windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: 1.5pt solid windowtext;
        }

        .xl79 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: 1.5pt solid windowtext;
            mso-pattern: black none;
        }

        .xl80 {
            mso-style-parent: style0;
            mso-number-format: "\\$0\.00";
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: 1.5pt solid windowtext;
        }

        .xl81 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: 1.5pt solid windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: 1.5pt solid windowtext;
        }

        .xl82 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: 1.5pt solid windowtext;

            mso-pattern: black none;
        }

        .xl83 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: 1.5pt solid windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl84 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl85 {
            mso-style-parent: style0;
            mso-number-format: "\\$0\.00";
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: 1.5pt solid windowtext;
        }

        .xl86 {
            mso-style-parent: style0;
            mso-number-format: "\\$0\.00";
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl87 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border: 1.5pt solid windowtext;
        }

        .xl88 {
            mso-style-parent: style0;
            font-weight: 700;
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: 1.5pt solid windowtext;
        }

        .xl89 {
            mso-style-parent: style0;
            font-weight: 700;
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl90 {
            mso-style-parent: style0;
            font-weight: 700;
            mso-number-format: "\\$0\.00";
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: 1.5pt solid windowtext;
        }

        .xl91 {
            mso-style-parent: style0;
            font-weight: 700;
            mso-number-format: "\\$0\.00";
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl92 {
            mso-style-parent: style0;
            font-weight: 700;
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: 1.5pt solid windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl93 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: 1.5pt solid windowtext;
        }

        .xl94 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: 1.5pt solid windowtext;
        }

        .xl95 {
            mso-style-parent: style0;
            font-size: 14.0pt;
            font-weight: 700;
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: 1.5pt solid windowtext;
        }

        .xl96 {
            mso-style-parent: style0;
            font-size: 14.0pt;
            font-weight: 700;
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl97 {
            mso-style-parent: style0;
            font-size: 14.0pt;
            font-weight: 700;
            mso-number-format: "\\$0\.00";
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl98 {
            mso-style-parent: style0;
            font-size: 14.0pt;
            font-weight: 700;
            mso-number-format: "\\$0\.00";
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: 1.5pt solid windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl99 {
            mso-style-parent: style0;
            font-size: 14.0pt;
            font-weight: 700;
            text-align: center;
            vertical-align: middle;
            border-top: 1.5pt solid windowtext;
            border-right: 1.5pt solid windowtext;
            border-bottom: .5pt dashed windowtext;
            border-left: .5pt dashed windowtext;
        }

        .xl100 {
            mso-style-parent: style0;
            text-align: center;
            vertical-align: middle;
            border-top: .5pt dashed windowtext;
            border-right: .5pt dashed windowtext;
            border-bottom: 1.5pt solid windowtext;
            border-left: 1.5pt solid windowtext;
            background: #FFFF33;
            mso-pattern: black none;
        }

        .xl101 {
            mso-style-parent: style0;
            text-align: left;
            vertical-align: middle;
            border: .5pt dashed windowtext;
        }

        .xl102 {
            mso-style-parent: style0;
            text-align: right;
            vertical-align: middle;
            border: .5pt dashed windowtext;
        }
    }

    .portRatorReportTbl {

        table,
        td,
        th {
            border: 0.5pt dotted windowtext;
        }

        table {
            width: 100%;
            border-collapse: collapse;
        }

        td {
            font-size: 12.0pt;
            font-weight: 400;
            font-style: normal;
            text-decoration: none;
            font-family: Calibri;
            text-align: center;
            vertical-align: middle;
            white-space: nowrap;
        }

        td:first-child {
            background: white;
        }

        .yellow-bg {
            background: #FFFF33;
        }

        .green-bg {
            background: green;
        }

        .light-green {
            background: #4CAE4C;
        }

        .red-bg {
            background: #E11414;
        }

        .title {
            font-size: 24pt;
            border: none;
            height: 30pt;
            padding: 0;
            border: 1px solid #fff;
        }

        .bold-font {
            font-weight: bold;
        }

        .double-border {
            border-style: double;
        }

        .border-all {
            border: 1.5pt solid windowtext !important;
        }

        .b-bottom {
            border-bottom: 1.5pt solid windowtext !important;
        }

        .b-top {
            border-top: 1.5pt solid windowtext !important;
        }

        .b-left {
            border-left: 1.5pt solid windowtext !important;
        }

        .b-right {
            border-right: 1.5pt solid windowtext !important;
        }
    }

}

.tooltip-design>div {
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    padding: 8px 16px;
    background: #ddeaf3;
    font-size: 1rem;
    border-radius: 20px;
    border: 1px solid rgba(0, 114, 184, 0.5);
    font-weight: 500;
    color: #0072B8;
    opacity: 1;
}

.helpicon {
    background: #fff url(/media/svg/ico_help.svg) no-repeat center center;
    background-size: 36px;
    opacity: 1;
    border-radius: 50%;
    color: rgba(255, 255, 255, .9);
    position: fixed;
    bottom: 40px;
    left: auto;
    right: 65px;
    cursor: pointer;
    z-index: 999;
    width: 36px;
    height: 36px;
}