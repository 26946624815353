
.SummaryAnalysis__main {
  .suggest__actionbox {
    background: #edf4f8;
    border: 1px solid #dbdbdb;
    border-radius: 10px;
    padding: 24px 30px;
    max-width: 632px;
    .suggest__actionbox__btn {
      .btn {
        padding: 12px 20px;
      }
    }
  }
}