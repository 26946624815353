.ToolboxSubCategory__main {
  &.SubCategory__fullbg {
    width: 100%;
    // height: 100%;
    border: none;
    position: absolute;
    left: 0;
    right: 0;

    top: 37px;
    padding: 35px;
    background: #eef0f8;
  }

  .Toolbox__top__contant {
    padding: 0 0 45px;
    display: flex;
    justify-content: space-between;

    h2 {
      color: #101011;
      font-size: 35px;
      line-height: 53px;
      font-weight: 500;
      margin: 0;
      padding-bottom: 2px;
      display: flex;
      align-items: center;

      a {
        margin-right: 25px;
        height: 44px;
        width: 44px;

        .backarrow {
          background: url(/media/svg/arrow_next_black.svg) no-repeat 0 0;
          background-size: 44px;
          height: 44px;
          width: 44px;
          display: inline-block;
          transform: rotate(180deg);
        }
      }
    }

    p {
      color: #101011;
      font-weight: 400;
      font-size: 18px;
      line-height: 27px;
      margin: 0;
      padding: 0 0 0 70px;
      max-width: 680px;
    }
  }

  .SubCategory__middle__block {
    display: flex;

    .SubCategory__contant__text {
      // max-width: 400px;
      flex: 1;
      padding-right: 30px;

      h3 {
        color: #101011;
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        margin: 0 0 5px;
        // white-space: nowrap;
      }

      p {
        color: #101011;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        margin: 0;
        max-width: 300px;
      }
    }

    .illustration__img {
      // max-width: 588px;
      flex: 1 1 auto;
      position: relative;

      img {
        max-width: 100%;
        position: relative;
        z-index: 0;
        width: 454px;
        height: 297px;
      }

      &::before {
        content: "";
        background: url(/media/misc/lines.svg) no-repeat 0 0;
        background-size: 100%;
        height: 66%;
        width: 100%;
        max-width: 840px;
        position: absolute;
        left: -54%;
        right: 0;
        margin: 0 auto;
        z-index: 0;
        top: 49%;
      }

      &.border0 {
        &::before {
          display: none;
        }
      }

      &.border1 {
        &::before {
          background: url(/media/misc/line-border1.svg) no-repeat 0 0;
          max-width: 10px;
          top: 85%;
          left: -40%;
        }
      }

      &.border2 {
        &::before {
          background: url(/media/misc/line-border2.svg) no-repeat 0 0;
          max-width: 160px;
          top: 85%;
          left: -40%;
        }
      }

      &.border3 {
        &::before {
          background: url(/media/misc/line-border3.svg) no-repeat 0 0;
          max-width: 435px;
          top: 85%;
          left: -40%;
        }
      }

      &.border4 {
        &::before {
          background: url(/media/misc/line-border4.svg) no-repeat 0 0;
          left: -40%;
          max-width: 745px;
        }
      }
    }
  }

  .Toolbox__bottom__Slider {
    max-width: 1055px;
    margin: 45px auto 0;

    .slick-list {
      .slick-track {
        display: flex !important;
        justify-content: center;

        .slick-slide {
          margin: 0 13px;
          max-width: 185px !important;
          min-width: 185px;
        }
      }
    }

    .slick-slider {
      h3 {
        color: #101011;
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        margin: 0 0 5px;
      }

      .slick-arrow {
        height: 45px;
        width: 45px;
        max-height: 45px;
        max-width: 45px;
        cursor: pointer;
        border: none;
        font-size: 0;
        z-index: 1002;
        position: absolute;
        top: 50%;

        &::before {
          opacity: 0;
        }

        &.slick-prev {
          background: url(/media/svg/Slide_Leftarrow.svg) no-repeat 0 0;
          background-size: 45px;
          left: -25px;
        }

        &.slick-next {
          background: url(/media/svg/Slide_Rightarrow.svg) no-repeat 0 0;
          background-size: 45px;
          right: -25px;
        }
      }
    }

    .whitebox {
      background: #fff;
      box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.05);
      border-radius: 10px 10px 0px 0px;
      padding: 12px 15px;
      border: 2px solid #fff;
      transition-duration: 1s;

      &:hover {
        border-color: #0072b8;
      }

      .box__title {
        display: flex;
        align-items: center;
        padding-bottom: 5px;

        .que__text {
          white-space: nowrap;
          max-width: 125px;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #000;
        }

        .arrownext {
          background: url(/media/svg/arrow_next_black.svg) no-repeat 0 0;
          background-size: 16px;
          height: 18px;
          width: 18px;
          flex: 0 0 18px;
          display: inline-block;
          margin: 0 0 0 auto;
          cursor: pointer;
        }
      }

      .boximg {
        height: 100px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    .SubCategory__hover__bluebox {
      background: #031a28 url(/media/misc/bg_blue.png) no-repeat 0 0;
      background-size: cover;
      box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      padding: 35px;
      max-width: 360px;
      margin: 0 auto;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 35%;
      z-index: 9999;

      h4 {
        border-bottom: 1px solid #fff;
        padding-bottom: 18px;
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 20px;
        line-height: 30px;
        color: #fff;
      }

      .bluebox__contant {
        label {
          padding-top: 20px;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #e7edff;
          margin: 0;
        }

        p {
          margin: 0;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: #fff;
        }
      }

      .scroll__hidden {
        // overflow: hidden;
        margin-right: -20px;
        width: 100%;
        word-wrap: break-word;
      }

      .description-text {
        max-height: 120px;
        overflow: hidden;
        overflow-y: auto;
        width: -webkit-fill-available;
        margin-right: -20px;
        scrollbar-width: none;
      }
    }
  }

  .bottom-fixed {
    width: 100%;
    margin: 0 auto;
    max-width: 1140px;
    overflow: hidden;

    .bottom__tagline {
      background: #474573;
      border-radius: 100px;
      color: #fff;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      border: 3px solid #f7bd67;
    }

    .bottom-imgline {
      max-height: 24px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 1199px) {
  .ToolboxSubCategory__main {
    .SubCategory__middle__block {
      .illustration__img {
        &:before {
          left: -30%;
          top: 70%;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .ToolboxSubCategory__main {
    .Toolbox__bottom__Slider {
      max-width: 100%;
    }

    .Toolbox__top__contant {
      h2 {
        font-size: 28px;
        line-height: 32px;

        a {
          width: 34px;
          height: 34px;

          .backarrow {
            width: 34px;
            height: 34px;
            background-size: 34px;
          }
        }
      }

      p {
        padding-left: 60px;
        font-size: 16px;
      }
    }

    .bottom-fixed {
      max-width: 100%;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ToolboxSubCategory__main {
    &.SubCategory__fullbg {
      top: 55px;
      padding: 20px;
    }

    .Toolbox__top__contant {
      h2 {
        font-size: 25px;
        line-height: 38px;

        a {
          width: 30px;
          height: 30px;

          .backarrow {
            width: 30px;
            height: 30px;
            background-size: 30px;
          }
        }
      }

      p {
        display: none;
      }

      .rightcol__logo {
        display: none;
      }
    }

    .SubCategory__middle__block {
      .SubCategory__contant__text {
        display: none;
      }

      .illustration__img {
        &:before {
          display: none;
        }
      }
    }

    .Toolbox__bottom__Slider {
      .slick-slider {
        .slick-arrow {
          height: 30px;
          width: 30px;
          max-height: 30px;
          max-width: 30px;

          &.slick-prev {
            left: -15px;
            background-size: 30px;
          }

          &.slick-next {
            right: -15px;
            background-size: 30px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .ToolboxSubCategory__main {
    .Toolbox__bottom__Slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            margin: 0 10px;
            max-width: 100% !important;
          }
        }
      }

      .whitebox {
        margin: 0 15px;

        .box__title {
          padding-bottom: 10px;

          .que__text {
            font-size: 16px;
            line-height: 24px;
          }
        }

        .boximg {
          height: 155px;
        }
      }
    }

    .Toolbox__top__contant {
      h2 {
        font-size: 18px;
        line-height: 20px;

        a {
          width: 24px;
          height: 24px;
          margin-right: 10px;

          .backarrow {
            width: 24px;
            height: 24px;
            background-size: 24px;
          }
        }
      }
    }

    .bottom-fixed {
      .bottom__tagline {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
}
