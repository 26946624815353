
.TraversalComments__main {
    .commentsbox {
      background: #edf4f8;
      border: 1px solid #dbdbdb;
      border-radius: 10px;
      padding: 24px 30px;
      max-width: 632px;
      p {
        font-size: 22px;
        line-height: 32px;
      }
      .action__btn {
        .btn {
          padding: 12px 20px;
        }
      }
    }
  }