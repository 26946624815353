.TraversalHeader__main {
  .card {
    &.card-custom {
      .card-header {
        padding: 32px 40px;
        border-bottom: none;
        background: #edf4f8;
        .card-title {
          align-items: flex-start;
          .card-label {
            font-size: 1.5rem;
          }
          .text-primary {
            color: #0072b8;
          }
          a {
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            padding: 0;
            margin: 0;
          }
        }
      }
    }
  }
}

.traversal__modal {
  .modal-dialog {
    max-width: 625px;
    p {
      font-size: 14px;
      line-height: 18px;
      color: #7e8299;
      padding: 0;
      margin: 0;
    }
  }
}

.modal__crossicon {
  background: transparent url(/media/svg/ico_cross.svg) no-repeat 0 0;
  border: none;
  background-size: 15px;
  height: 20px;
  width: 20px;
  font-size: 15px;
}

.summary__list {
  border-bottom: 1px solid #f1f1f1;
  padding: 15px;
  &:last-child {
    border: none;
  }
  .number {
    font-size: 14px;
    line-height: 18px;
    color: #000;
  }
}
