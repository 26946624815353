/* .header-color-conf { */
/* background-color: #1a1a27; */
/* } */

.header-fixed .header-menu .menu-nav > .menu-item > .menu-link > .menu-arrow {
  display: flex;
}

.header-fixed .header-menu .menu-nav > .menu-item .menu-submenu {
  box-shadow: 0px 0px 9px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  max-width: 192px;
}

.header-fixed .header-menu .menu-nav > .menu-item .menu-submenu .menu-subnav {
  padding: 0 14px;
}

.header-fixed
  .header-menu
  .menu-nav
  > .menu-item
  .menu-submenu
  .menu-subnav
  .menu-item
  .menu-link {
  border-radius: 8px;
  padding: 7px 12px;
  margin: 2px 0;
}
