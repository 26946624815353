.TraversalRadioButton__main {
  .form-group {
    margin-bottom: 0px;
  }
  .radio__input__box {
    position: relative;
    margin-right: 40px;
    .radio-button {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      margin: 0;
      cursor: pointer;
    }
    .radio-tile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: #ffffff;
      border: 1px solid #edeeef;
      width: 146px;
      height: 146px;
      text-align: center;
      max-width: 146px;
      box-shadow: 0px 0px 18px rgba(#000, 0.08);
      border-radius: 5px;
      transition: transform 300ms ease;
      .radio-tile-label {
        text-align: center;
        font-weight: 700;
        font-size: 25px;
        line-height: 38px;
        color: #101011;
        margin: 0;
        .radio__circle {
          width: 40px;
          height: 40px;
          margin: 0 auto 12px;
          border: 1px solid #bdbdc8;
          background: #f0f0f0;
          border-radius: 50%;
          position: relative;
          cursor: pointer;
        }
      }
    }
    .radio-button:checked + .radio-tile {
      background-color: #0072b8;
      border-color: #0072b8;
      .radio-tile-label {
        color: #fff;
        background-color: #0072b8;
        .radio__circle {
          border: 1px solid #fff;
          background: #fff;
          &::after {
            content: "";
            width: 20px;
            height: 20px;
            background: #e57f0e;
            border-radius: 50%;
            position: absolute;
            left: 0;
            right: 0;
            top: 9px;
            bottom: auto;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .TraversalRadioButton__main {
    .radio__input__box {
      margin-right: 15px;
      .radio-tile {
        width: 125px;
        height: 125px;
        max-width: 125px;
      }
    }
  }
}
