.ProgressBar__main {
  height: 3px;
  width: 100%;
  background: #d8d9db;
  .progressBar__filed {
    height: 100%;
    background: #31ad3d;
    transition: width 1s ease-in-out;
    &.filed10 {
      width: 10%;
    }
    &.filed20 {
      width: 20%;
    }
    &.filed30 {
      width: 30%;
    }
    &.filed40 {
      width: 40%;
    }
    &.filed50 {
      width: 50%;
    }
    &.filed60 {
      width: 60%;
    }
    &.filed70 {
      width: 70%;
    }
    &.filed80 {
      width: 80%;
    }
    &.filed90 {
      width: 90%;
    }
    &.filed100 {
      width: 100%;
    }
  }
}
