
  
  /* .custom-table tr:nth-child(old){
    background-color: #f2f2f2;
  } */

  .custom-table tr:nth-child(even){
    background-color: #f9f9f9;
  }
  
/*   
  #table-responsive table,
  #table-responsive table th, td {
    border: 1px solid #e9e9e9;
  } */