.helpicon_right {
    background: #fff url(/media/svg/ico_help.svg) no-repeat center center;
    background-size: 36px;
    opacity: 1;
    border-radius: 50%;
    color: rgba(255, 255, 255, .9);
    position: fixed;
    bottom: 40px;
    left: 42px;
    right: auto;
    cursor: pointer;
    z-index: 999;
    width: 36px;
    height: 36px;
}

