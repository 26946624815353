.travers-row {
  
  grid-template-columns: 300px 1fr;
  grid-gap: 20px;
  &.form-add {
	  grid-template-columns: 300px 1fr 300px;
  }
}


.travers-col
 {
	background: #fff;
	border: 1px solid #E5EAEE;
	height: calc(100vh - 205px);
  border-radius: 0.42rem;
}

.form-col {
  overflow-y: auto;
  height: 100%;
  border-radius: 0.42rem;
  margin-top: 0;
  border: none;
}

.diagram-col .goTXarea {
  resize: none;
  height: auto;
  font-size: 14px !important;
  color: #000000;
}


@media only screen and (max-width: 1199px) {
  .diagram-row {
    &.form-add {
      grid-template-columns: 300px 1fr 240px;
    }
  }
} 


.card-custom .tooltip {
  max-width: 200px;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0px 0px 18px rgba(#523f69, 0.05);
  padding: 8px 16px;
  background: #ddeaf3;
  font-size: 1rem;
  border-radius: 20px;
  border: 1px solid rgba(0, 114, 184, 0.5);
  font-weight: 500;
  color: #0072B8;
  opacity: 1;
  z-index: 9;
}

.card-custom .tooltip::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent rgba(0, 114, 184, 0.5)  transparent transparent;
}

.tooltip .tooltip-chip{
  margin: 8px;
}