
.WizardStep__container {
    position: relative;
    display: flex;
    align-items: center;
    border-top: 1px solid #ebebeb;
}

.WizardStep__container #RFS-StepperContainer {
    width: 420px;
    margin: 0 auto;
}

.WizardStep__container #RFS-ConnectorContainer {
    top: calc((3em - 1px) / 2);
}

.WizardStep__container #RFS-Connector {
    border-width: 2px;
    border-color: #0072B8;
}

.WizardStep__container .step__arrows {
    position: absolute;
    left: 0;
    right: 0;
}

#RFS-StepButton {
    height: 40px;
    width: 40px;
    background: #E0EEF6;
    border: 4px solid transparent;
}

#RFS-StepButton.active,
#RFS-StepButton.completed {
    background: #00578C;
    border: 4px solid #0072B8;
    height: 40px;
    width: 40px;
}

#RFS-StepButton span {
    font-size: 0;
}

#RFS-LabelContainer span {
    font-size: 20px;
    font-family: Poppins, Helvetica, "sans-serif";
    line-height: 30px;
    margin-top: 8px;
}

.step__btns__block, 
.action__btn {
    display: flex;
    align-items: center;
    height: 52px;
}

.step__btns__block .action__btn button {
    display: inline-block;
    border: none;
    height: 52px;
    width: auto;
}

.step__btns__block .action__btn button.btn__back {
    background: transparent url(/media/svg/arrow_back.svg) no-repeat center center;
    background-size: 52px;
    width: 52px;
    position: absolute;
    left: 32px;
}

.step__btns__block .action__btn button.btn__next {
    background: transparent url(/media/svg/arrow_next.svg) no-repeat center center;
    background-size: 52px;
    width: 52px;
    position: absolute;
    right: 32px;
}

.step__btns__block .action__btn button.btn__update {
    position: absolute;
    right: 32px;
}