.Traversal__main__block {
  .card {
    &.card-custom {
      box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.08);
      border-radius: 10px;

      .Traversal__container {
        display: flex;
      }

      .Traversal__leftcol {
        flex: 0 0 480px;
        border-right: 2px solid #d8d9db;
        text-align: center;
        padding: 30px 20px 20px;
        position: relative;

        .illustration__img {
          max-width: 300px;
          margin: 0 auto;
          position: relative;

          img {
            max-width: 300px;
            position: relative;
            z-index: 1;
          }

          &::before {
            content: "";
            height: 100%;
            width: 2px;
            border: 2px dashed #000000;
            position: absolute;
            left: 0;
            right: 0;
            margin: 0 auto;
            z-index: 0;
            top: 60px;
          }
        }

        .Traversal__whitebox {
          background: #fff;
          box-shadow: 0px -2px 15px rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          padding: 12px 15px;
          border: 2px solid #d8d9db;
          text-align: center;
          max-width: 160px;
          margin: 60px auto;

          .box__title {
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            color: #000;
            padding-bottom: 5px;
          }

          .boximg {
            height: 100px;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }

        .pow-logo {
          max-width: 160px;
          margin: 0 auto;
          // position: absolute;
          // left: 0;
          // right: 0;
          // bottom: 20px;
        }
      }

      .Traversal__Rightcol {
        flex: 1 1 auto;

        .card-body {
          .card-header {
            display: flex;
            justify-content: space-between;
            padding: 0px;
            border: 0px;
            margin-bottom: 10px;
          }
        }

        .condition-main {
          .condition-items {
            display: flex;
            align-items: center;

            .color-box {
              display: block;
              width: 20px;
              height: 20px;
              background-color: #31ad3d;
              border: 1px solid black;
              border-radius: 0px;
            }

            h4 {
              margin: 0;
              font-size: 13px;
            }
          }
        }
      }

      .card-body {
        padding: 2.25rem;

        .input-block {
          margin-bottom: 20px;
        }

        .per__form__group {
          position: relative;

          span {
            background-color: #f3f6f9;
            color: #101011;
            font-size: 20px;
            border-right: 1px solid #cdd0da;
            width: 64px;
            max-width: 64px;
            height: 100%;
            position: absolute;
            left: 0;
            text-align: center;
            right: 0;
            padding: 12px;
            margin-right: 30px;
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
          }

          .form-control {
            text-indent: 60px;
          }
        }

        .traversal__product__block {
          margin-top: 20px;

          .card-header {
            display: none;
          }

          .card-body {
            padding: 0;
            margin-top: 20px;

            .card {
              background: none;
              box-shadow: none;

              .col-lg-6 {
                flex: 0 0 300px;
              }
            }
          }
        }

        .traversal__map__block {
          padding-top: 36px;

          .custom-map-checkbox {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .form-group {
            margin-bottom: 0;

            .checkbox-inline {
              justify-content: center;
              margin: 0;

              svg {
                width: 24px;
                height: 24px;
              }
            }

            label {
              margin: 0;
              color: rgba(16, 16, 17, 0.5);
            }
          }

          .usa__map {
            width: 100%;
            max-width: 720px;
            margin: 0 auto;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .Traversal__main__block {
    .card.card-custom {
      .Traversal__leftcol {
        flex: 0 0 240px;
        padding: 20px;

        .illustration__img {
          max-width: 240px;

          img {
            max-width: 240px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .Traversal__main__block {
    .card.card-custom {
      .Traversal__container {
        flex-direction: column;
      }
    }
  }
}

@media only screen and (max-width: 540px) {
  .Traversal__main__block {
    .card.card-custom {
      .card-body {
        padding: 20px;
      }

      .Traversal__leftcol {
        flex: 1;
        padding: 15px;
      }
    }
  }
}

.manual-input .react-datepicker-wrapper {
  width: 100%;
}

.card.card-custom .text-div {
  display: flex;
  align-items: center;
}

.report-div-iframe {
  border: 0;
}

.report-div-html {
  max-height: 400px;
  overflow-y: scroll;
  height: 100%;
  background-color: #fff;

  tbody {
    background-color: #ffffff;
  }

  .networkComputationReportTbl {
    tr {
      mso-height-source: auto;
    }

    col {
      mso-width-source: auto;
    }

    br {
      mso-data-placement: same-cell;
    }

    .bt-rm {
      border-top: none !important;
    }

    .bl-rm {
      border-left: none !important;
    }

    .br-rm {
      border-right: none !important;
    }

    .bb-rm {
      border-bottom: none !important;
    }

    .green-bg {
      background: #b4de86;
    }

    .border-all {
      border: 1.5pt solid windowtext !important;
    }

    .border-rm {
      border: none !important;
    }

    .b-bottom {
      border-bottom: 1.5pt solid windowtext !important;
    }

    .b-top {
      border-top: 1.5pt solid windowtext !important;
    }

    .b-left {
      border-left: 1.5pt solid windowtext !important;
    }

    .b-right {
      border-right: 1.5pt solid windowtext !important;
    }

    .style0 {
      mso-number-format: General;
      text-align: general;
      vertical-align: bottom;
      white-space: nowrap;
      mso-rotate: 0;
      mso-background-source: auto;
      mso-pattern: auto;
      color: windowtext;
      font-size: 12pt;
      font-weight: 400;
      font-style: normal;
      text-decoration: none;
      font-family: Calibri;
      mso-generic-font-family: auto;
      mso-font-charset: 0;
      border: none;
      mso-protection: locked visible;
      mso-style-name: Normal;
      mso-style-id: 0;
    }

    td {
      mso-style-parent: style0;
      padding-top: 1px;
      padding-right: 1px;
      padding-left: 1px;
      mso-ignore: padding;
      color: windowtext;
      font-size: 12pt;
      font-weight: 400;
      font-style: normal;
      text-decoration: none;
      font-family: Calibri;
      mso-generic-font-family: auto;
      mso-font-charset: 0;
      mso-number-format: General;
      text-align: general;
      vertical-align: bottom;
      border: none;
      mso-background-source: auto;
      mso-pattern: auto;
      mso-protection: locked visible;
      white-space: nowrap;
      mso-rotate: 0;
    }

    .xl65 {
      mso-style-parent: style0;
      font-size: 26pt;
      font-weight: 700;
      text-align: left;
      vertical-align: middle;
      border: 0.5pt dashed windowtext;
    }

    .xl66 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border: 0.5pt dashed windowtext;
    }

    .xl67 {
      mso-style-parent: style0;
      mso-number-format: "\\$0\.00";
      text-align: center;
      vertical-align: middle;
      border: 0.5pt dashed windowtext;
    }

    .xl68 {
      mso-style-parent: style0;
      font-size: 14pt;
      font-weight: 700;
      text-align: center;
      vertical-align: middle;
      border: 0.5pt dashed windowtext;
    }

    .xl69 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 1.5pt solid windowtext;
    }

    .xl70 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl71 {
      mso-style-parent: style0;
      mso-number-format: "\\$0\.00";
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl72 {
      mso-style-parent: style0;
      mso-number-format: "\\$0\.00";
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 1.5pt solid windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl73 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 0.5pt dashed windowtext;
      background: #ffff33;
      mso-pattern: black none;
    }

    .xl74 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 1.5pt solid windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl75 {
      mso-style-parent: style0;
      font-weight: 700;
      text-align: center;
      vertical-align: middle;
      border: 0.5pt dashed windowtext;
    }

    .xl76 {
      mso-style-parent: style0;
      font-weight: 700;
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 1.5pt solid windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 1.5pt solid windowtext;
    }

    .xl77 {
      mso-style-parent: style0;
      mso-number-format: "\\$0\.00";
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 1.5pt solid windowtext;
    }

    .xl78 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 1.5pt solid windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 1.5pt solid windowtext;
    }

    .xl79 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 1.5pt solid windowtext;
      mso-pattern: black none;
    }

    .xl80 {
      mso-style-parent: style0;
      mso-number-format: "\\$0\.00";
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 1.5pt solid windowtext;
    }

    .xl81 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 1.5pt solid windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 1.5pt solid windowtext;
    }

    .xl82 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 1.5pt solid windowtext;

      mso-pattern: black none;
    }

    .xl83 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 1.5pt solid windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl84 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl85 {
      mso-style-parent: style0;
      mso-number-format: "\\$0\.00";
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 1.5pt solid windowtext;
    }

    .xl86 {
      mso-style-parent: style0;
      mso-number-format: "\\$0\.00";
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl87 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border: 1.5pt solid windowtext;
    }

    .xl88 {
      mso-style-parent: style0;
      font-weight: 700;
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 1.5pt solid windowtext;
    }

    .xl89 {
      mso-style-parent: style0;
      font-weight: 700;
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl90 {
      mso-style-parent: style0;
      font-weight: 700;
      mso-number-format: "\\$0\.00";
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 1.5pt solid windowtext;
    }

    .xl91 {
      mso-style-parent: style0;
      font-weight: 700;
      mso-number-format: "\\$0\.00";
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl92 {
      mso-style-parent: style0;
      font-weight: 700;
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 1.5pt solid windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl93 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 1.5pt solid windowtext;
    }

    .xl94 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 1.5pt solid windowtext;
    }

    .xl95 {
      mso-style-parent: style0;
      font-size: 14pt;
      font-weight: 700;
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 1.5pt solid windowtext;
    }

    .xl96 {
      mso-style-parent: style0;
      font-size: 14pt;
      font-weight: 700;
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl97 {
      mso-style-parent: style0;
      font-size: 14pt;
      font-weight: 700;
      mso-number-format: "\\$0\.00";
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl98 {
      mso-style-parent: style0;
      font-size: 14pt;
      font-weight: 700;
      mso-number-format: "\\$0\.00";
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 1.5pt solid windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl99 {
      mso-style-parent: style0;
      font-size: 14pt;
      font-weight: 700;
      text-align: center;
      vertical-align: middle;
      border-top: 1.5pt solid windowtext;
      border-right: 1.5pt solid windowtext;
      border-bottom: 0.5pt dashed windowtext;
      border-left: 0.5pt dashed windowtext;
    }

    .xl100 {
      mso-style-parent: style0;
      text-align: center;
      vertical-align: middle;
      border-top: 0.5pt dashed windowtext;
      border-right: 0.5pt dashed windowtext;
      border-bottom: 1.5pt solid windowtext;
      border-left: 1.5pt solid windowtext;
      background: #ffff33;
      mso-pattern: black none;
    }

    .xl101 {
      mso-style-parent: style0;
      text-align: left;
      vertical-align: middle;
      border: 0.5pt dashed windowtext;
    }

    .xl102 {
      mso-style-parent: style0;
      text-align: right;
      vertical-align: middle;
      border: 0.5pt dashed windowtext;
    }
  }

  .portRatorReportTbl {

    table,
    td,
    th {
      border: 0.5pt dotted windowtext;
    }

    table {
      width: 100%;
      border-collapse: collapse;
    }

    td {
      font-size: 12pt;
      font-weight: 400;
      font-style: normal;
      text-decoration: none;
      font-family: Calibri;
      text-align: center;
      vertical-align: middle;
      white-space: nowrap;
    }

    td:first-child {
      background: white;
    }

    .yellow-bg {
      background: #ffff33;
    }

    .green-bg {
      background: green;
    }

    .light-green {
      background: #4cae4c;
    }

    .red-bg {
      background: #e11414;
    }

    .title {
      font-size: 24pt;
      height: 30pt;
      padding: 0;
      border: 1px solid #000;
    }

    .bold-font {
      font-weight: bold;
    }

    .double-border {
      border-style: double;
    }

    .border-all {
      border: 1.5pt solid windowtext !important;
    }

    .b-bottom {
      border-bottom: 1.5pt solid windowtext !important;
    }

    .b-top {
      border-top: 1.5pt solid windowtext !important;
    }

    .b-left {
      border-left: 1.5pt solid windowtext !important;
    }

    .b-right {
      border-right: 1.5pt solid windowtext !important;
    }
  }
}

.grid-manual-input {
  flex-direction: inherit;
  display: flex;
  align-items: center;

  .input-title {
    width: 30%;
  }

  .manual-input {
    width: 70%;
  }
}