.state-view {
    outline: none;
}

.state-view:focus {
    outline: none;
}

.state-view:hover {
    outline: none;
    fill: #0072B8;
    cursor: pointer;
}

.state-text-view {
    outline: none;
    fill: inherit;
    cursor: pointer;
    font-size: 8px !important;
    font-weight: 500;
}

.state-view-tooltip {
    background-color: none;
}

.state-view-tooltip > div {
    padding: 10px;
    font-size: 10px;
    margin-top: 0px;
    background-color: #b36103;
}