.ToolboxLanding__main {
  .ToolboxLanding__fullbg {
    width: 100%;
    height: 100%;
    border: none;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
    top: 65px;
  }
  .Toolbox__top__contant {
    position: absolute;
    z-index: 1000;
    text-align: center;
    left: 0;
    right: 0;
    max-width: 680px;
    margin: 0 auto;
    h3 {
      color: #101011;
      font-size: 25px;
      line-height: 38px;
      font-weight: 700;
      margin: 0;
      padding-bottom: 2px;
    }
    p {
      color: #101011;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      margin: 0 auto;
      max-width: 560px;
    }
  }
  .Toolbox__bottom__contant {
    position: fixed;
    z-index: 1002;
    left: 0;
    display: flex;
    align-items: center;
    text-align: left;
    bottom: 15px;
    .pow-logo {
      margin-right: 40px;
      max-width: 160px;
    }
    p {
      color: #fff;
      font-weight: 600;
      font-size: 18px;
      line-height: 27px;
      margin: 58px 0 0 50px;
      a {
        color: yellow;
      }
    }
  }

  .information {
    justify-content: center;
    width: 100%;
    text-align: center;
  }

  .comp-logo {
    position: absolute;
    right: 20%;
    top: 40%;
    z-index: 1002;
    border: 1px solid #9f9c93;
    padding: 10px 20px;
    transform: rotate(5deg);
    max-width: 210px;
    max-height: 80px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 180px;
      max-height: 60px;
    }
  }
  .enter__imgfixed {
    position: absolute;
    right: 20px;
    bottom: 0;
    z-index: 1003;
    cursor: pointer;
  }
}

.ToolboxLanding__fullbg {
  background: url(/media/bg/Toolbox_bg1.png) no-repeat 0 0;
  background-size: 100% 100%;
}

@media only screen and (max-width: 1024px) {
  .ToolboxLanding__main {
    .ToolboxLanding__fullbg {
      top: 55px;
      background-size: cover;
      background-attachment: fixed;
    }
    .Toolbox__bottom__contant {
      .pow-logo {
        margin-right: 20px;
      }
      p {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .ToolboxLanding__main {
    .Toolbox__top__contant,
    .Toolbox__bottom__contant {
      display: none;
    }
  }
}
