
.thankyou__modal {
  .statusicon {
    &.success {
      background: transparent url(/media/svg/ico_checkmarkSuccess.svg) no-repeat
        0 0;
      border: none;
      background-size: 145px;
      height: 145px;
      width: 145px;
      margin: 0 auto 30px;
    }
  }
  .text__des {
    font-size: 1.5rem;
    line-height: 24px;
    color: #000;
  }
}