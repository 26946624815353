
.StartTravesal__main {
  .start__text {
    color: #0072b8;
  }
  .btn__start {
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    max-width: 100px;
    max-height: 100px;
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    animation: bouncein 2s normal forwards ease-in-out
  }
}

@keyframes bouncein {
	0% {
		opacity: 0;
		transform: scale(0.3);
	}
	50% {
		opacity: 1;
		transform: scale(1.05);
	}
	70% {
		transform: scale(0.9);
	}
	100% {
		transform: scale(1);
	}
}