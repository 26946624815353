.ToolboxCategory__main {
  .ToolboxCategory__fullbg {
    width: 100%;
    height: 100%;
    border: none;
    position: fixed;
    left: 0;
    right: 0;
    z-index: 999;
    top: 65px;
  }

  .Toolbox__top__contant {
    position: absolute;
    z-index: 1000;
    text-align: center;
    left: 0;
    right: 0;
    max-width: 680px;
    margin: 0 auto;

    h3 {
      color: #101011;
      font-size: 25px;
      line-height: 38px;
      font-weight: 700;
      margin: 0;
      padding-bottom: 2px;
    }

    p {
      color: #101011;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      margin: 0 auto;
      max-width: 635px;
    }
  }

  .Category__Directory__card {
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin: 0 auto;
    z-index: 999;
    background: linear-gradient(270deg, #38aca8 0%, #5f637a 100%);
    border: 3px solid #2e344c;
    padding: 18px;
    max-width: 500px;
    color: #fff;

    h3 {
      font-weight: 700;
      font-size: 26px;
      line-height: 39px;
      margin: 0;
      padding-bottom: 15px;
    }

    .Category__DirectoryList {
      max-height: 170px;
      overflow: hidden;
      overflow-y: auto;
      margin-right: -20px;

      .ListName {
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
          color: #fff;
          font-weight: 600;
          font-size: 24px;
          line-height: 36px;
          cursor: pointer;
          text-transform: capitalize;
          flex: 0 0 80%;

          &:hover {
            color: #f5f5f5;
          }
        }

        .List__rightCol {
          display: flex;
          align-items: center;
          flex: 0 0 20%;
          justify-content: center;

          .ListCount {
            background: rgba(255, 255, 255, 0.7);
            border: 1px solid #fff;
            width: 25px;
            height: 25px;
            max-width: 25px;
            max-height: 25px;
            flex: 0 0 25px;
            border-radius: 50%;
            overflow: hidden;
            display: inline-block;
            color: #0072b8;
            font-weight: 700;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
            margin-right: 15px;
          }

          .Listarrow {
            background: url(/media/svg/next_arrow_white.svg) no-repeat 0 0;
            background-size: 8px;
            height: 14px;
            width: 14px;
            display: inline-block;
            flex: 0 0 14px;
          }
        }
      }
    }

    .btn__logout {
      font-size: 14px;
      line-height: 21px;
      text-transform: uppercase;
      margin: 20px 0 0 auto;
      display: block;
      width: 85px;
    }
  }

  .Toolbox__bottom__contant {
    position: fixed;
    z-index: 1002;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    left: 0;
    right: 0;
    // background: #ecedf0;
    padding: 24px 20px;
    max-width: 52%;
    margin: 0 auto;

    .contant__text {
      flex: 1 1 auto;
    }

    h3 {
      color: #101011;
      font-weight: 700;
      font-size: 22px;
      line-height: 33px;
      margin: 0 0 5px;
    }

    p {
      color: #101011;
      font-weight: 500;
      font-size: 17px;
      line-height: 21px;
      margin: 0;
    }

    .pow-logo {
      flex: 0 0 160px;
      max-width: 160px;
    }
  }

  .comp-logo {
    position: absolute;
    left: 45px;
    top: 36%;
    z-index: 1001;
    border: 2px solid #000;
    background: #d8d9db;
    padding: 5px;
    max-width: 245px;
    /* max-height: 111px; */
    /* min-width: 200px; */
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      max-width: 200px;
      max-height: 90px;
    }
  }
}

.ToolboxCategory__fullbg {
  background: url(/media/bg/Toolbox_bg2.png) no-repeat 0 0;
  background-size: 100% 100%;
}

@media only screen and (max-width: 1024px) {
  .ToolboxCategory__main {
    .ToolboxCategory__fullbg {
      top: 55px;
      background-size: cover;
      background-attachment: fixed;
    }

    .comp-logo {
      left: 5px;
      top: 25%;
    }

    .Category__Directory__card {
      max-width: 380px;
      top: 38%;

      .scroll__hidden {
        margin: 0;
      }

      h3 {
        font-size: 20px;
      }

      .Category__DirectoryList {
        margin: 0;

        .ListName {
          a {
            font-size: 18px;
            line-height: 27px;
          }
        }
      }
    }

    .Toolbox__bottom__contant {
      flex-direction: column;
      align-items: flex-start;
      max-width: 100%;

      .contant__text {
        margin-right: 0;
      }

      .pow-logo {
        flex: auto;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .ToolboxCategory__main {

    .Toolbox__top__contant,
    .Toolbox__bottom__contant,
    .comp-logo {
      display: none;
    }

    .Category__Directory__card {
      max-width: 90%;
      top: 25%;
    }
  }
}

// landscape

@media only screen and (min-device-width: 414px) and (max-device-width: 896px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
  .ToolboxCategory__main {

    .Toolbox__top__contant,
    .Category__Directory__card,
    .Toolbox__bottom__contant {
      position: relative;
      margin-top: 20px;
    }

    .comp-logo {
      top: 50%;
    }
  }
}