.TraversalFooter__main {
  .traversal__action__btn {
    text-align: right;
    .btn {
      text-transform: uppercase;
      width: 140px;
      font-size: 16px;
      height: 48px;
    }
  }
}
